import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField
} from '@mui/material';

import DialogTitle from './DialogTitle';
import { PromptDialogProps } from './types';

const PromptDialog = (props: PromptDialogProps) => {
  const [value, setValue] = useState<string>('');
  return (
    <Dialog {...props.dialogProps} open={true} onClose={() => props.callback(null)}>
      <form onSubmit={() => props.callback(value)}>
        <DialogTitle onClose={props.showClose && (() => props.callback(null))}>{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {props.message}
          </DialogContentText>
          <TextField value={value} onChange={e => setValue(e.target.value)} {...props.textFieldProps} />
        </DialogContent>
        <DialogActions>
          <Button type="button" onClick={() => props.callback(null)} color="primary">
            {props.cancelText}
          </Button>
          <Button type="submit" color="primary" autoFocus>
            {props.submitText}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default PromptDialog;
