import React from 'react';
import {
  IconButton,
  DialogTitle as MuiDialogTitle,
  DialogTitleProps as MuiDialogTitleProps
} from '@mui/material';
import {
  Close as CloseIcon
} from '@mui/icons-material';

export interface DialogTitleProps extends MuiDialogTitleProps {
  onClose?: false | (() => void)
}

const DialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;
  return (
    <MuiDialogTitle {...other} sx={(theme) => ({
      position: 'relative',
      paddingRight: theme.spacing(7),
      minWidth: '350px'
    })}>
      {children}
      {!!onClose && (
        <IconButton onClick={onClose} sx={(theme) => ({
          position: 'absolute',
          right: theme.spacing(1),
          top: '50%',
          transform: 'translateY(-50%)',
          color: theme.palette.grey[500],
        })}>
          <CloseIcon />
        </IconButton>
      )}
    </MuiDialogTitle>
  );
};

export default DialogTitle;
