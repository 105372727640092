import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import Axios, {
  CLIENT_OTP_TOKEN_STORAGE_KEY,
  initializeAxiosInstance,
} from "../../libraries/Axios";
import { ClientInterface } from "../../models/Clients";

const CheckoutSETC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [verifying, setVerifying] = useState(false);
  const [client, setClient] = useState<ClientInterface>();
  let navigateTimeout: NodeJS.Timeout;

  useEffect(() => {
    verifyPaymentInformation();

    return () => {
      clearTimeout(navigateTimeout);
    };
  }, []);

  const verifyPaymentInformation = async () => {
    initializeAxiosInstance();
    setVerifying(true);
    try {
      const currentUserProfile = await Axios.get("/api/clients/me");
      if (currentUserProfile.data.total > 0) {
        const profile = currentUserProfile.data.data[0];
        setClient(profile);

        let queryParams: { [key: string]: string } = {};

        searchParams.forEach((value, key) => {
          queryParams[key] = value;
        });

        await Axios.post("/api/clients/setc-checkout", {
          queryParams,
        });
      }
    } catch (e: any) {
      window.localStorage.removeItem(CLIENT_OTP_TOKEN_STORAGE_KEY);
      window.localStorage.removeItem("ERTC_CALCULATOR_DATA");
      navigate("/one-time-pin");
    } finally {
      setVerifying(false);
      navigateTimeout = setTimeout(() => {
        navigate("/long-questionnaire-setc");
      }, 5000);
    }
  };

  return (
    <Box className="pt-40">
      <Stack className="grid place-items-center" spacing={4}>
        <img
          src="/assets/media/images/ERTCExpressLogo.png"
          alt="ERTC Express Logo"
          width={250}
        />
        <Typography variant="h4">
          Thank you for your payment,{" "}
          <strong>{client?.firstName || "Client"}</strong>!
        </Typography>
        <Box className="flex items-center flex-col">
          {verifying ? <CircularProgress sx={{ mb: 2 }} /> : null}
          <Typography variant="subtitle1" textAlign="center">
            {verifying
              ? "Please hang on for a while we verify your payment information..."
              : "Payment has been verified. We'll redirect you back to the long questionnaire page in a few seconds..."}
          </Typography>
        </Box>
      </Stack>
    </Box>
  );
};

export default CheckoutSETC;
