import { useState, useContext, createContext, ComponentProps, useEffect } from 'react';
import * as Sentry from "@sentry/react";

import User, { IUser } from '../../models/User';
import { ADMIN_TOKEN_STORAGE_KEY, reinitializeAxiosInstance } from '../../libraries/Axios';
import feathersClient from '../../feathers';
import ErtcLoaderAnimation from '../../components/LoadingComponents/ertcLoaderAnimation';

export interface AdminAuthContextState {
  user: User | null
  loginWithEmail: (email: string, password: string) => Promise<void>
  logout: () => Promise<void>
}

interface LoginResponse {
  accessToken: string
  authentication: {
    accessToken: string
    payload: { [key: string]: any }
    strategy: string
  }
  user: IUser
}

const AdminAuthContext = createContext<AdminAuthContextState>({} as AdminAuthContextState);

export const AdminAuthProvider = (props: ComponentProps<any>) => {
  const { children } = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [user, setUser] = useState<User | null>(null);

  // login with email
  const loginWithEmail = async (email: string, password: string) => {
    await feathersClient.authenticate({
      strategy: 'local',
      email,
      password
    })

    const { accessToken, user } = await feathersClient.get('authentication')
    Sentry.setUser({ email: user.email, id: user._id })

    window.localStorage.setItem(ADMIN_TOKEN_STORAGE_KEY, accessToken);
    setUser(new User(user));
    reinitializeAxiosInstance(accessToken);
  };

  // logout
  const logout = async () => {
    await feathersClient.logout()
    reinitializeAxiosInstance('')
    window.localStorage.removeItem(ADMIN_TOKEN_STORAGE_KEY);
    setUser(null);
    window.localStorage.clear()
  };

  // reauthenticate using stored token
  const initialize = async () => {
    try {
      setIsLoading(true);
      const token = window.localStorage.getItem(ADMIN_TOKEN_STORAGE_KEY);
      if (token) {
        await feathersClient.reAuthenticate()
        const { accessToken, user } = await feathersClient.get('authentication')
        window.localStorage.setItem(ADMIN_TOKEN_STORAGE_KEY, accessToken);
        setUser(new User(user));
        reinitializeAxiosInstance(accessToken)
        Sentry.setUser({ email: user.email, id: user._id })
      }
    } catch (e) {
      setUser(null);
      window.localStorage.clear()
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    initialize();
  }, [])

  return (
    <AdminAuthContext.Provider
      value={{
        user,
        loginWithEmail,
        logout
      }}>
      {(() => {
        if (isLoading) {
          return (
            <div className='h-screen w-screen'>
              <ErtcLoaderAnimation />
            </div>
          );
        }
        
        return children;
      })()}
    </AdminAuthContext.Provider>
  );
};

export const useAdminAuth = (): AdminAuthContextState => useContext(AdminAuthContext);
