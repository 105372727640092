import { GlobalStyles as MuiGlobalStyles } from '@mui/material';
import { useTheme } from '@mui/material';
import { CSSProperties } from 'react';

interface CssStyle {
  [key: string]: string | number
}

// the main purpose of this component is to normalize the styles of html text tags
// the styles will be synced from material-ui's typography
const GlobalStyles = () => {

  const theme = useTheme();

  const extendStyle = (base: CSSProperties, extension: CSSProperties = {}): CssStyle => {
    return {...base, ...extension};
  };

  return (
    <MuiGlobalStyles styles={{
      '.tox.tox-tinymce-aux [role="menuitemcheckbox"] *': {
        lineHeight: 1.1
      },
      'blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre': {
        margin: 0
      },
      '.mce-content-body': {
        padding: '10px'
      },
      h1: extendStyle(theme.typography.h1),
      h2: extendStyle(theme.typography.h2),
      h3: extendStyle(theme.typography.h3),
      h4: extendStyle(theme.typography.h4),
      h5: extendStyle(theme.typography.h5),
      h6: extendStyle(theme.typography.h6),
      p: extendStyle(theme.typography.body1),
      '.MuiTypography-subtitle1': extendStyle(theme.typography.subtitle1),
      '.MuiTypography-subtitle2': extendStyle(theme.typography.subtitle2),
      '.MuiTypography-body1': extendStyle(theme.typography.body1),
      '.MuiTypography-body2': extendStyle(theme.typography.body2),
      '.MuiTypography-button': extendStyle(theme.typography.button),
      '.MuiTypography-caption': extendStyle(theme.typography.caption),
      '.MuiTypography-overline': extendStyle(theme.typography.overline),
      ol: extendStyle({
        paddingInlineStart: '40px',
        listStyleType: 'decimal',
        margin: 0
      }),
      ul: extendStyle({
        paddingInlineStart: '40px',
        listStyleType: 'disc',
        margin: 0
      })
    }} />
  );
};

export default GlobalStyles;
