import React, { useState, useContext } from 'react';

import AlertDialog from './AlertDialog';
import ConfirmDialog from './ConfirmDialog';
import PromptDialog from './PromptDialog';
import {
  AlertDialogProps as AlertItem,
  ConfirmDialogProps as ConfirmItem,
  PromptDialogProps as PromptItem
} from './types';

interface AlertOptions {
  title?: AlertItem['title']
  showClose?: AlertItem['showClose']
  dialogProps?: AlertItem['dialogProps']
  okText?: AlertItem['okText']
}

interface ConfirmOptions {
  title?: ConfirmItem['title']
  showClose?: ConfirmItem['showClose']
  dialogProps?: ConfirmItem['dialogProps']
  confirmText?: ConfirmItem['confirmText']
  cancelText?: ConfirmItem['cancelText']
}

interface PromptOptions {
  title?: PromptItem['title']
  showClose?: PromptItem['showClose']
  dialogProps?: PromptItem['dialogProps']
  submitText?: PromptItem['submitText']
  cancelText?: PromptItem['cancelText']
  textFieldProps?: PromptItem['textFieldProps']
}

export interface DialogContextState {
  alert: (message: AlertItem['message'], options?: AlertOptions) => Promise<void>
  confirm: (message: ConfirmItem['message'], options?: ConfirmOptions) => Promise<boolean>
  prompt: (message: PromptItem['message'], options?: PromptOptions) => Promise<string|null>
}

export const DialogContext = React.createContext<DialogContextState>({
  alert: async(message: AlertItem['message']) => window.alert(String(message)),
  confirm: async(message: ConfirmItem['message']) => window.confirm(message),
  prompt: async (message: PromptItem['message']) => window.prompt(message)
});

export const DialogProvider = (props: React.ComponentProps<any>) => {
  const [alertItems, setAlertItems] = useState<AlertItem[]>([]);
  const [confirmItems, setConfirmItems] = useState<ConfirmItem[]>([]);
  const [promptItems, setPromptItems] = useState<PromptItem[]>([]);

  const alert = (message: AlertItem['message'], options?: AlertOptions): Promise<void> => new Promise<void>(resolve => {
    const item: AlertItem = {
      title: options?.title || 'Alert',
      message: message,
      showClose: !!options?.showClose,
      dialogProps: options?.dialogProps || {},
      okText: options?.okText || 'Ok',
      callback() {
        resolve();
        setAlertItems(alertItems.filter(a => a !== item));
      }
    };
    setAlertItems([...alertItems, item]);
  });

  const confirm = (message: ConfirmItem['message'], options?: ConfirmOptions): Promise<boolean> => new Promise<boolean>(resolve => {
    const item: ConfirmItem = {
      title: options?.title || 'Confirm',
      message: message,
      showClose: !!options?.showClose,
      dialogProps: options?.dialogProps || {},
      confirmText: options?.confirmText || 'Confirm',
      cancelText: options?.cancelText || 'Cancel',
      callback(confirmed: boolean) {
        resolve(confirmed);
        setConfirmItems(confirmItems.filter(a => a !== item));
      }
    };
    setConfirmItems([...confirmItems, item]);
  });

  const prompt = (message: PromptItem['message'], options?: PromptOptions): Promise<string|null> => new Promise<string|null>(resolve => {
    const item: PromptItem = {
      title: options?.title || 'Prompt',
      message: message,
      showClose: !!options?.showClose,
      dialogProps: options?.dialogProps || {},
      submitText: options?.submitText || 'Submit',
      cancelText: options?.cancelText || 'Cancel',
      textFieldProps: options?.textFieldProps || {},
      callback(value: string|null) {
        resolve(value);
        setPromptItems(promptItems.filter(a => a !== item));
      }
    };
    setPromptItems([...promptItems, item]);
  });

  return (
    <DialogContext.Provider value={{alert, confirm, prompt}}>
      {alertItems.map((data, index) => (<AlertDialog {...data} key={index} />))}

      {confirmItems.map((data, index) => (<ConfirmDialog {...data} key={index} />))}

      {promptItems.map((data, index) => (<PromptDialog {...data} key={index} />))}

      {props.children}
    </DialogContext.Provider>
  );
};

export const useDialog = (): DialogContextState => useContext(DialogContext);
