import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import * as Sentry from "@sentry/react";

// import reportWebVitals from './reportWebVitals';
import App from './App';
import './index.css';

if (process.env.REACT_APP_ENVIRONMENT === 'production') {
   Sentry.init({
      dsn: "https://7d4b2c712c644349926435c6a1afd107@o1001081.ingest.sentry.io/6489231",
      replaysOnErrorSampleRate: 0.5,
      integrations: [
         new Sentry.Replay()
      ],
      allowUrls: [
         'https://portal.ertcexpress.com/',
         'https://cdn.ertcexpress.com/',
      ],
   });

   /**
    * Leaving this code here for future reference on how to dynamically load a script
    * in the head of the document depending on the environment.
    */
   // const head = document.head;
   // const script = document.createElement('script');
   // script.type = 'text/javascript';
   // script.defer = true;
   // script.async = false;
   // script.src = "https://route.ertc.com/v1/lst/universal-script?ph=5fb30b78ab4439089bd4dbb1c05fec7e7f0bc196fb2351ef9e102b22a335fe90&tag=!clicked&ref_url=" + encodeURI(document.URL);
   // head.appendChild(script);
}

ReactDOM.render(
   <React.StrictMode>
      <BrowserRouter>
         <App />
      </BrowserRouter>
   </React.StrictMode>,
   document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
