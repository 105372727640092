import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material';

import DialogTitle from './DialogTitle';
import { ConfirmDialogProps } from './types';

const ConfirmDialog = (props: ConfirmDialogProps) => {
  return (
    <Dialog {...props.dialogProps} open={true} onClose={() => props.callback(false)}>
      <DialogTitle onClose={props.showClose && (() => props.callback(false))}>{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ whiteSpace: 'pre-line' }}>
          {props.message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.callback(false)} color="primary">
          {props.cancelText}
        </Button>
        <Button onClick={() => props.callback(true)} color="primary" autoFocus>
          {props.confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
