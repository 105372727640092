import feathers from '@feathersjs/feathers'
import socketio from '@feathersjs/socketio-client'
import io from 'socket.io-client'
import auth from '@feathersjs/authentication-client'

/**
 * Todo: Update this to use environmnet variables for the server
 */
const socket = io(process.env.REACT_APP_API || 'http://localhost:3030', { transports: ['websocket'], upgrade: false, timeout: 10000 });
const feathersClient = feathers();

// Set up Socket.io client with the socket
feathersClient.configure(socketio(socket));
feathersClient.configure(auth())

const globalListeners: {[key: string]: any} = {}

export const globalListener = (id: string, callback: any) => {
    if (globalListeners[id]) {
        console.warn(`Listener with id ${id} already exists`)
        return
    }

    globalListeners[id] = callback
    feathersClient.service("api/clients").on(`patched`, globalListeners[id])
}

export const removeGlobalListener = (id: string) => {
    if (!globalListeners[id]) {
        console.warn(`Listener with id ${id} does not exist`)
        return
    }

    feathersClient.service("api/clients").removeListener(`patched`, globalListeners[id])
    delete globalListeners[id]
}

export default feathersClient