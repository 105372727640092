import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material';

import DialogTitle from './DialogTitle';
import { AlertDialogProps } from './types';

const AlertDialog = (props: AlertDialogProps) => {
  return (
    <Dialog {...props.dialogProps} open={true} onClose={() => props.callback()}>
      <DialogTitle onClose={props.showClose && (() => props.callback())}>{props.title}</DialogTitle>
      <DialogContent>
        {(() => {
          if (typeof props.message === 'string')
            return (<DialogContentText>{props.message}</DialogContentText>);
          return props.message;
        })()}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.callback()} color="primary" autoFocus>
          {props.okText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertDialog;
