import React, { ComponentProps } from 'react';
import { ThemeProvider, CssBaseline } from '@mui/material';
import MainTheme from '../../themes/main';
import GlobalStyles from '../GlobalStyles';
import { DialogProvider } from '../../context/Dialog';

const GlobalProviders = (props: ComponentProps<any>) => {
  const { children } = props;
  return (
    <ThemeProvider theme={MainTheme}>
      <CssBaseline />
      <GlobalStyles />
      <DialogProvider>
        {children}
      </DialogProvider>
    </ThemeProvider>
  );
};

export default GlobalProviders;
